.App {
  background: url(../public/3009e722ec057ffab1e41d2f993385d1.png) no-repeat;
  background-size: repeat;
  background-position: center;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin: 0px;
  background-size: cover;
  background-image: auto;
  flex-direction:column;
  background-color: #020016;
  /* color: #020016; */
}



.window{
  max-width: 80%;
  min-height: 50%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 10px 10px 10px 10px #000;
  border-width: 1px;
  border-color: #ddd;
  margin: 100px;
  padding-bottom: 20px;
  padding-right: 20px;
  justify-content: center;
  align-items: center;


}

.navbar{
  flex-direction: row;
  justify-content: space-around;
  display: flex;
  align-items: center;
  padding:20px;
  padding-top: 0px;
  padding-bottom: 0px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;

}

.navbarRight{
  flex-direction: row;
  display: flex;
}

.navbarRight h2{
  margin-right: 20px;
}

.aboutme{
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutmeRight img{
  padding-left: 20px;
  height: 40vh;
  max-height:500px;
  justify-content: center;
  display: flex;
  align-items: center;
  
}

.aboutmeLeft h4{
  padding: 30px;
  text-align: center;
}



.aboutmeLeft{
  /* padding:50px; */
  padding-top: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
}

.aboutmeLeft h2{
  font-size: 30px;
}

.aboutmeLeft h3{
  font-size: 40px;
}

.circle1, .circle2, .circle3{
  height:20px;
  width:20px;
  border-radius: 10px;
  background-color: #FF605C;
  margin-left: 10px;
  margin-top: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.circle2{
  background-color: #FFBD44;
}

.circle3{
  background-color: #00CA4E;
}

.circle1Button{
  background-color: #FF605C;
  height: 20px;
  width: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: none;
  outline: none;
  display: none;
}

.circle1Button:hover{
  display: block;
}

.threecircles{
  display: flex;
  flex-direction: row;
  padding:10px
}

.headerLink {
  color:#000;
  text-decoration: none;
  font-size: 15px;
  font-weight: none;
  padding-left: 20px;
}

.headerLink:hover{
  text-decoration:underline;
}

.mobileNavBar{
  flex-direction: column;
  position: fixed;
  top: 90px;
  background-color: white;
  width: 100%;
  transition: height 2s;
  transition-timing-function: linear;
}

.mobileNavBar .headerLink{
  text-align: center;
}

.pageButton{
  background-color: #020016;
  padding: 10px;
  min-width: 150px;
  height: 50px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  border-radius: 10px;
  border-width: 3px;
  margin: 10px;
  border-color: #020016;  
}
.pageButton:hover{
  background-color: white;
  color: black;
  border-width: 3px;
  border-color: #020016;  

}

.pageButtonContainer{
  min-width: 400px;
  margin-top: 50px;
}

.headerTitle{
  padding: 20px;
  margin-top: 0px;
  font-weight: bold;
}

.projectFlatList {
  padding: 20px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow: scroll;
  
}

.projectFlatList::-webkit-scrollbar {
  display: none;
}

.projectFlatList::-webkit-scrollbar {
  display: none;
}


.indyItem{
  max-width: 300px;
  border-width: 0.8px;
  margin-right: 20px;
  border-style: solid;
  border-color: gray;
  box-shadow: 0px 4px 8px 0px lightcyan;
}

.hamburger{
  padding-right: 10px;
}

.indyItemInnerContainer{
  padding: 10px;
}

.indyItemInnerContainer h4{
  color: gray;
  font-size: 15px;
}

.window h3{
  color: gray;
  padding: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.indyItemLink{
  text-decoration: none;
  color: #000;
  border-width: 0.5px;
  border-color: #000;
  padding-right: 20px;
}

/* experience */
.indyExperienceItem{
  display: flex;
  /* flex-direction: row; */
  border-width: 1px;
  border-color: gray;
  border-style: solid;
  /* min-width: 100%; */
  /* margin-right: 20px; */
  text-decoration: none;
  color: black;
  margin-bottom: 20px;
  
}

.experienceFlatList::-webkit-scrollbar {
  display: none;
}

.experienceFlatList::-webkit-scrollbar {
  display: none;
}

.experienceFlatList{
  padding: 20px;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
  
}

.experienceParagraph {
  font-size: 15px;
  width:'10%';
  text-decoration: none;
  max-width: 50%;
  padding:20px;
  padding-top: 0px;
}

.experienceBox{
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start
}
.experienceBox img{
  max-height: 200px;
  max-width: 20%;
  /* resize: contain; */
}
.innerExperienceBox{
  /* display: flex; */
  padding: 10px;
  max-width:30%
  /* flex-direction: column; */
  /* align-items: flex-start; */
  /* text-align: left; */
  /* text-decoration: none; */
  /* justify-content: flex-start; */

}

.innerExperienceBox .title{
  margin-top: 0px;
  font-size: 30px;
  text-decoration: none;
}

.innerExperienceBox h4{
  margin-top: 0px;
  text-align: left;
}

.experienceDuration {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: gray;
}

.pdfSection{
  padding-left: 10px;
  
}

/* footer */

.footerMain{
  background-color:white;
  display:block;
  width:100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content:'center';
  /* padding: 10px; */
  padding-bottom: 40px;
  border-top-width: 1px;
  border-style: solid;
  border-color: gray;
}

.footerMain h2{
  color:gray;

}

.footerMain h3{
  font-size: 15px;
}

.footerContent{
  display: flex;
  flex-direction:row;
  /* background-color:gray; */
  justify-content:space-around;
  align-items: flex-start;
  width:100%;
  flex-wrap:nowrap;
}
.leftlink{
  /* margin-right: 20px; */
  display: flex;
  flex-direction:column;
}
.centerlink{
  /* margin-right: 20px; */
  display: flex;
  flex-direction:column;
}
.rightlink{
  /* margin-right: 20px; */
  display: flex;
  flex-direction:column;
}

.footerlinks{
  margin-top: 10px;
  text-decoration: none;
  color:gray;
}

.footerlinks:hover{
  text-decoration: underline;
}

/* mobile */
@media only screen and (max-width: 1000px) {
  .App{
    justify-content:center;
  }

  .window{
    min-width: 100%;
  }

  .aboutme{
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: gray; */
  }

  .aboutmeRight img{
    padding: 0px;
  }

  .experienceBox{
    flex-direction: column;
  }
  .experienceParagraph{
    max-width: 100%;
    padding-left: 10px;
  }
  .innerExperienceBox{
    max-width: 100%;
  }

  .experienceBox img{
    padding-left: 10px;
  }
  .footerContent{
    flex-direction: column
  }

  .footerMain{
    padding-left: 30px
  }
}


@media only screen and (max-width: 800px) {
  .window{
    min-width: 100%;
    height:100%;
    border-radius: 0px;
    margin: 0px;
    margin-top: 90px;
    box-shadow: 0px 0px 0px 0px #000;
    border-width: 0px;
    padding:0px
  }

  .aboutmeLeft{
    justify-content: center;
    align-items: center;
  }

  .aboutmeLeft h3{
    font-size: 40px;
    /* max-width: 80%; */
  }

  .navbar{
    justify-content: space-between;
    padding: 10px;
  }

  .App{
    width: 100%;
    /* background-color: green; */
  }
  

  
  .pageButton{
    width: 70%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .pageButtonContainer{
    padding: 0px;
  }
}